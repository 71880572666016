import React, { lazy } from "react";
import {
  UserAccount,
  Signup,
  ResetPasswordPage,
  SetupNewMemberPassword,
  UserProfile,
  ForgotPassword,
  CheckEmail,
  UserManagement,
  MemberSignupForm,
  InvitationList,
  Invitation,
  BrokerageManagement,
  BrokerageSignupForm,
  BrokerageEcosystemManagement,
  BrokerageMemberSignupForm,
  BrokerageCardPage,
  TeamManagement,
  TeamAndGroupsPageTabs,
  NewMemberSignupForm,
  NewGroupMemberSignupForm,
  GroupCard,
  TierManagement,
  SupportManagement,
  ErrorLogsManagement,
  InvitationLogsManagement,
  DataEntryLogsManagement,
  TicketPage,
  CategoryManagement,
  CategorySettingsManagement,
  CategorySettingsItemPage,
  MembersPage,
  MemberProfilePage,
  RoleManagement,
  PostPage,
  CategoryItemPage,
  CategoryTypeItemPage,
  RedirectBasedOnRole,
  RoleItemPage,
  TierItemPage,
  PostManagement,
  Billing,
  ConfirmSwapPage,
  DutiesManagement,
  ConfirmSelectedDutyTimeSlot,
  FeatureSettingsManagement,
  ListingTourManagement,
  ListingCard,
} from "./components";
import FilePostCard from "./components/posts/FilePostCard";
import CustomSystemDetector from "./components/helpers/CustomSystemDetector";
import LoginWithToken from "./services/custom-hooks/LoginWithToken";
import RedirectHandler from "./services/routes/RedirectHandler";
const Home = lazy(() => import("./components/home-page"));
const Login = lazy(() => import("./components/auth/login"));
const CategoryPage = lazy(() => import("./components/CategoryPage"));

export const routes = [
  { path: "/", element: <RedirectBasedOnRole /> },
  {
    path: "/login",
    element: (
      <>
        <CustomSystemDetector />
        <Login />
      </>
    ),
  },
  { path: "/reosadmin-signup", element: <Signup /> },
  { path: "/reset-password/:userId/:token", element: <ResetPasswordPage /> },
  {
    path: "/setup-password/:token",
    element: <SetupNewMemberPassword />,
  },
  { path: "/forgot-password/", element: <ForgotPassword /> },
  { path: "/check-email/", element: <CheckEmail /> },
  {
    path: "/register-brokerage-member",
    element: <BrokerageMemberSignupForm />,
  },
  { path: "/register-new-member", element: <MemberSignupForm /> },
  { path: "/register-new-member-to-team", element: <NewMemberSignupForm /> },
  {
    path: "/register-new-member-to-group",
    element: <NewGroupMemberSignupForm />,
  },
  { path: "/register-brokerage", element: <BrokerageSignupForm /> },
  { path: "/login-via-token", element: <LoginWithToken /> },
  { path: "/R/:code", element: <RedirectHandler /> },
];

export const protectedRoutes = [
  { path: "/home", element: <Home /> },
  { path: "/category/:categoryPath/:categoryId", element: <CategoryPage /> },
  { path: "/profile", element: <UserAccount /> },
  { path: "/user-management", element: <UserManagement /> },
  {
    path: "/brokerage-ecosystem-management",
    element: <BrokerageEcosystemManagement />,
  },
  {
    path: "/brokerage-management",
    element: <BrokerageManagement />,
  },
  {
    path: "/brokerage-card/:brokerageId",
    element: <BrokerageCardPage />,
  },
  {
    path: "/post-management",
    element: <PostManagement />,
  },
  {
    path: "/team-management",
    element: <TeamManagement />,
  },
  {
    path: "/team/:teamId",
    element: <TeamAndGroupsPageTabs />,
  },
  {
    path: "/group/:groupId",
    element: <GroupCard />,
  },
  {
    path: "/tier-management",
    element: <TierManagement />,
  },
  {
    path: "/support-management",
    element: <SupportManagement />,
  },
  {
    path: "/error-logs-management",
    element: <ErrorLogsManagement />,
  },
  {
    path: "/invitation-logs-management",
    element: <InvitationLogsManagement />,
  },
  {
    path: "/data-entry-logs-management",
    element: <DataEntryLogsManagement />,
  },
  {
    path: "/duties-management",
    element: <DutiesManagement />,
  },
  {
    path: "/feature-settings-management",
    element: <FeatureSettingsManagement />,
  },
  {
    path: "/category-management",
    element: <CategoryManagement />,
  },
  {
    path: "/category-settings-management",
    element: <CategorySettingsManagement />,
  },
  {
    path: "/role-management",
    element: <RoleManagement />,
  },
  {
    path: "/listing-tour-management",
    element: <ListingTourManagement />,
  },
  { path: "/user/:userId", element: <UserProfile /> },
  { path: "/invitation-list", element: <InvitationList /> },
  { path: "/invitation/:invitationId", element: <Invitation /> },
  {
    path: "/category/:categoryItemId",
    element: <CategoryItemPage />,
  },
  {
    path: "/category-settings/:categorySettingsId",
    element: <CategorySettingsItemPage />,
  },
  {
    path: "/category-type/:categoryTypeItemId",
    element: <CategoryTypeItemPage />,
  },
  {
    path: "/members/:categoryName",
    element: <MembersPage />,
  },
  { path: "/files/", element: <FilePostCard /> },
  {
    path: "/member-profile/:userId",
    element: <MemberProfilePage />,
  },
  {
    path: "/post/:postId",
    element: <PostPage />,
  },
  {
    path: "/role/:roleId",
    element: <RoleItemPage />,
  },
  {
    path: "/tier/:tierId",
    element: <TierItemPage />,
  },
  {
    path: "/ticket/:ticketId",
    element: <TicketPage />,
  },
  {
    path: "/billing",
    element: <Billing />,
  },
  {
    path: "/confirm-swap/:sourceDutyId/:targetDutyId",
    element: <ConfirmSwapPage />,
  },
  {
    path: "/select-available-time-slot/:dutyId",
    element: <ConfirmSelectedDutyTimeSlot />,
  },
  {
    path: "/listing/:listingId",
    element: <ListingCard />,
  },
];
