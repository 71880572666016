import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  CardMedia,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Checkbox,
  CircularProgress,
  Tooltip,
  Paper,
  IconButton,
} from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import {
  useUpdateImageMutation,
  useUpdateUserNameMutation,
} from "../../services/user";
import { useGetDutyScheduleOfficeLocationsByUserRoleQuery } from "../../services/duty-schedule";
import {
  useGetMemberProfileQuery,
  useUpdateMemberProfileMutation,
} from "../../services/member";
import NotificationSnackbar from "../helpers/notification-snackbar";
import { timezones, isValidHttpUrl, daysOfWeek } from "../helpers/utils";
import TimeSlotSelector from "./TimeSlotSelector";
import UnavailablePeriodSelector from "./UnavailablePeriodSelector";
import dayjs from "dayjs";
import utc from "dayjs-plugin-utc";
dayjs.extend(utc);

const EditMemberProfile = ({
  userMemberId,
  memberProfileEditFrom,
  onClose,
}) => {
  const { userId, updateUserImage, accountType } = useContext(AuthContext);
  const {
    data: member,
    isLoading,
    refetch,
  } = useGetMemberProfileQuery(userMemberId, {
    skip: !userMemberId,
  });

  const { data: dutyScheduleOfficeLocations } =
    useGetDutyScheduleOfficeLocationsByUserRoleQuery(userMemberId, {
      skip: !userMemberId,
    });

  const [updateMemberProfile] = useUpdateMemberProfileMutation();
  const [memberData, setMemberData] = useState({
    profileTitle: "",
    email: "",
    isEmailPrivate: false,
    emailPublic: "",
    website: "",
    profession: "",
    bio: "",
    tags: [],
    timezone: "",
    certifications: [],
    awards: [],
    yearLicensed: "",
    licenseNumber: "",
    hobbies: [],
    geographicAreasServiced: [],
    gender: "",
    dealsPerYear: "",
    birthday: { day: "", month: "" },
    phone: "",
    socialMediaLinks: [],
    conferenceParticipantType: "",
    otherLinks: [],
    currentLocation: {
      city: "",
      state: "",
      country: "",
    },
    preferredCommunicationMethod: "",
    willingToDoDuty: false,
    availability: { days: [], timeSlots: [] },
    officeLocations: [],
    unavailablePeriods: [],
  });
  const [updateImage] = useUpdateImageMutation();
  const [selectedDays, setSelectedDays] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [updateUserName] = useUpdateUserNameMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await updateImage({
          userId: userMemberId,
          file,
        });
        if (response.error) {
          throw response.error;
        }
        if (response?.data) {
          setNotification({
            open: true,
            message: "Image updated successfully!",
          });
          refetch?.();
          setTimeout(() => {
            if (accountType !== "reosadmin" && userId === member?.user) {
              updateUserImage(response?.data?.image?.url);
            }
          }, 100);
        }
      } catch (error) {
        setNotification({
          open: true,
          message: `Failed to update image: ${error?.data?.msg}`,
        });
      }
    }
  };

  const handleSaveUserName = async () => {
    const result = await updateUserName({
      userId: member?.user,
      firstName,
      lastName,
      isEmailPrivate: memberData?.isEmailPrivate,
      emailPublic: memberData?.emailPublic,
    });
    if (result.data) {
      setNotification({
        open: true,
        message: "Name saved successfully.",
      });
      setTimeout(() => {
        refetch();
        if (memberProfileEditFrom) {
          onClose();
        }
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving changes: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleSubmit = async () => {
    if (memberData?.website && !isValidHttpUrl(memberData.website)) {
      setNotification({
        open: true,
        message: "Please enter a valid URL for the website.",
      });
      return;
    }

    const updatedMemberData = {
      ...memberData,
      availability: {
        days: selectedDays,
        timeSlots: timeSlots,
      },
      unavailablePeriods: [...memberData.unavailablePeriods],
      officeLocations: memberData.officeLocations.map((office) => ({
        officeName: office.officeName,
        location: office.location,
      })),
    };

    const resultUser = await updateUserName({
      userId: member?.user,
      firstName,
      lastName,
      isEmailPrivate: memberData?.isEmailPrivate,
      emailPublic: memberData?.emailPublic,
    });

    if (!resultUser.data) {
      setNotification({
        open: true,
        message: `Error saving changes: ${
          resultUser.error.data?.msg || "Unknown error"
        }`,
      });
    }

    const result = await updateMemberProfile({
      userId: userMemberId,
      updateData: updatedMemberData,
    });
    if (result.data) {
      setNotification({
        open: true,
        message: "Changes saved successfully.",
      });
      setTimeout(() => {
        refetch();
        if (memberProfileEditFrom) {
          onClose();
        }
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving changes: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleOfficeChange = (officeName, location) => {
    setMemberData((prevState) => {
      const officeExists = prevState.officeLocations.some(
        (office) => office.officeName === officeName
      );

      if (!officeExists) {
        return {
          ...prevState,
          officeLocations: [
            ...prevState.officeLocations,
            { officeName, location },
          ],
        };
      } else {
        return {
          ...prevState,
          officeLocations: prevState.officeLocations.map((office) =>
            office.officeName === officeName ? { officeName, location } : office
          ),
        };
      }
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "yearLicensed") {
      const yearValue = value.slice(0, 4);
      setMemberData({ ...memberData, [name]: yearValue });
    } else if (name === "isEmailPrivate") {
      // Convert the string value "yes" or "no" to a boolean
      const booleanValue = value === "yes";

      setMemberData({ ...memberData, [name]: booleanValue });
    } else if (name === "day" || name === "month") {
      setMemberData({
        ...memberData,
        birthday: { ...memberData.birthday, [name]: value || "" },
      });
    } else {
      setMemberData({ ...memberData, [name]: value || "" });
    }
  };

  const handleArrayChange = (name, value) => {
    const arrayValues = value.split(/, +/).map((item) => item);
    setMemberData((prevData) => ({
      ...prevData,
      [name]: arrayValues,
    }));
  };

  const handleDaysChange = (event) => {
    const { value } = event.target;
    setSelectedDays(typeof value === "string" ? value.split(",") : value);
  };

  const handleAddTimeSlot = (slot) => {
    setTimeSlots([...timeSlots, slot]);
  };

  const handleRemoveTimeSlot = (index) => {
    setTimeSlots((prevSlots) => prevSlots.filter((_, i) => i !== index));
  };

  const handleAddUnavailablePeriod = (period) => {
    setMemberData((prevData) => ({
      ...prevData,
      unavailablePeriods: [...prevData.unavailablePeriods, period],
    }));
  };

  const handleRemoveUnavailablePeriod = (index) => {
    setMemberData((prevData) => ({
      ...prevData,
      unavailablePeriods: prevData.unavailablePeriods.filter(
        (_, i) => i !== index
      ),
    }));
  };

  useEffect(() => {
    if (member) {
      setFirstName(member.firstName || "");
      setLastName(member.lastName || "");
      setMemberData((prevState) => ({
        ...prevState,
        profileTitle: member.profileTitle || "",
        email: member.email || "",
        emailPublic: member.emailPublic || "",
        isEmailPrivate: member.isEmailPrivate || false,
        website: member.website || "",
        bio: member.bio || "",
        phone: member.phone || "",
        timezone: member.timezone || "Default_Timezone",
        certifications: member.certifications || [],
        awards: member.awards || [],
        yearLicensed: member.yearLicensed || "",
        licenseNumber: member.licenseNumber || "",
        conferenceParticipantType:
          member.conferenceParticipantType || "Attendee",
        hobbies: member.hobbies || [],
        geographicAreasServiced: member.geographicAreasServiced || [],
        gender: member.gender || "Default_Gender",
        dealsPerYear: member.dealsPerYear || "",
        birthday: member.birthday
          ? member.birthday
          : { name: "", city: "", stateProvince: "", country: "" },
        office: member.office ? member.office : { day: "", month: "" },
        socialMediaLinks: member.socialMediaLinks || [],
        otherLinks: member.otherLinks || [],
        currentLocation: member.currentLocation || {
          city: "",
          state: "",
          country: "",
        },
        preferredCommunicationMethod:
          member.preferredCommunicationMethod || "Default_Method",
        willingToDoDuty: member.willingToDoDuty || true,
        availability: member.availability || { days: [], timeSlots: [] },
        unavailablePeriods: member.unavailablePeriods || [],
      }));
      setSelectedDays(member.availability.days || []);
      setTimeSlots(member.availability.timeSlots || []);
    }
  }, [member]);

  useEffect(() => {
    setMemberData((prevData) => ({
      ...prevData,
      officeLocations: member.officeLocations || [],
      availability: {
        ...prevData.availability,
        days: selectedDays,
        timeSlots: timeSlots,
      },
    }));
  }, [selectedDays, timeSlots]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );

  return (
    <div
      className={`${
        memberProfileEditFrom
          ? "member-profile-edit-form-from-mp"
          : "member-profile-edit-form-from-mp"
      }`}
    >
      <Grid container className="member-profile-edit-form-body">
        <Grid item container className="member-profile-edit-form-header">
          <div className="header-profile-image-block">
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="upload-image-file"
              type="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="upload-image-file">
              <Tooltip title="Upload Photo" arrow>
                <IconButton color="primary" component="span">
                  <CardMedia
                    component="img"
                    className="member-profile-image"
                    image={member?.image?.url}
                    alt={`${member?.firstName} ${member?.lastName}`}
                  />
                </IconButton>
              </Tooltip>
            </label>
            <Button
              variant="text"
              component="span"
              onClick={() =>
                document.getElementById("upload-image-file").click()
              }
            >
              {member?.image?.url ? "Change Photo" : "Add Photo"}
            </Button>
          </div>
          <div className="header-profile-title">
            <div className="header-profile-title-name">
              <TextField
                variant="outlined"
                margin="normal"
                label="First Name"
                name="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                className="header-profile-title-name-item"
              />
              <TextField
                variant="outlined"
                margin="normal"
                label="Last Name"
                name="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                className="header-profile-title-name-item"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveUserName}
                className="actions-button"
              >
                Save Name
              </Button>
            </div>
            <Typography
              gutterBottom
              className="header-profile-title-profile-title"
              component="div"
            >
              {member?.profileTitle || "---"}
            </Typography>
          </div>
        </Grid>
        <Grid container className="member-profile-edit-form-fields" spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="Title"
              name="profileTitle"
              value={memberData.profileTitle}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          {true && (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Conference Participant Type</InputLabel>
                  <Select
                    name="conferenceParticipantType"
                    value={memberData.conferenceParticipantType || "Attendee"}
                    onChange={handleChange}
                    label="Conference Participant Type"
                  >
                    {[
                      "Attendee",
                      "Organizer",
                      "Keynote Speaker",
                      "Panelist",
                      "Sponsor",
                      "Exhibitor",
                      "Media",
                      "Other",
                    ].map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Paper style={{ margin: "15px", padding: "16px" }}>
                Office Details
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Name"
                      name="officeName"
                      value={memberData.office?.name || ""}
                      onChange={(e) =>
                        setMemberData({
                          ...memberData,
                          office: {
                            ...memberData.office,
                            name: e.target.value,
                          },
                        })
                      }
                      fullWidth
                      variant="outlined"
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="City"
                      name="officeCity"
                      value={memberData.office?.city || ""}
                      onChange={(e) =>
                        setMemberData({
                          ...memberData,
                          office: {
                            ...memberData.office,
                            city: e.target.value,
                          },
                        })
                      }
                      fullWidth
                      variant="outlined"
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Province"
                      name="officeStateProvince"
                      value={memberData.office?.stateProvince || ""}
                      onChange={(e) =>
                        setMemberData({
                          ...memberData,
                          office: {
                            ...memberData.office,
                            stateProvince: e.target.value,
                          },
                        })
                      }
                      fullWidth
                      variant="outlined"
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Country"
                      name="officeCountry"
                      value={memberData.office?.country || ""}
                      onChange={(e) =>
                        setMemberData({
                          ...memberData,
                          office: {
                            ...memberData.office,
                            country: e.target.value,
                          },
                        })
                      }
                      fullWidth
                      variant="outlined"
                      margin="normal"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
          <Grid item xs={12}>
            <TextField
              label="Email Address"
              name="email"
              value={memberData.email}
              onChange={handleChange}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Make Email Private</FormLabel>
              <RadioGroup
                name="isEmailPrivate"
                value={memberData?.isEmailPrivate ? "yes" : "no"}
                onChange={handleChange}
                row
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {memberData?.isEmailPrivate && (
            <Grid item xs={12}>
              <TextField
                label="Public Email to Display"
                name="emailPublic"
                value={memberData.emailPublic}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                margin="normal"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label="Your Website"
              name="website"
              value={memberData.website}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="About You - Bio"
              name="bio"
              value={memberData.bio}
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Phone Number"
              name="phone"
              value={memberData.phone}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Timezone</InputLabel>
              <Select
                name="timezone"
                value={memberData.timezone}
                onChange={handleChange}
                label="Timezone"
              >
                {timezones?.map((tz) => (
                  <MenuItem key={tz} value={tz}>
                    {tz}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Gender</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender"
                value={memberData.gender}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="Female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Certifications"
              name="certifications"
              value={memberData.certifications.join(", ")}
              onChange={(e) =>
                handleArrayChange("certifications", e.target.value)
              }
              multiline
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Awards"
              name="awards"
              value={memberData.awards.join(", ")}
              onChange={(e) => handleArrayChange("awards", e.target.value)}
              multiline
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Year Licensed"
              name="yearLicensed"
              type="number"
              value={memberData.yearLicensed}
              onChange={handleChange}
              inputProps={{ minLength: 4, maxLength: 4 }}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="License Number"
              name="licenseNumber"
              value={memberData.licenseNumber}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Hobbies"
              name="hobbies"
              value={memberData.hobbies.join(", ")}
              onChange={(e) => handleArrayChange("hobbies", e.target.value)}
              multiline
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Geographic Areas Serviced"
              name="geographicAreasServiced"
              value={memberData.geographicAreasServiced.join(", ")}
              onChange={(e) =>
                handleArrayChange("geographicAreasServiced", e.target.value)
              }
              multiline
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              label="Deals Per Year"
              name="dealsPerYear"
              type="number"
              value={memberData.dealsPerYear}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid> */}
          <Grid item xs={12}>
            <TextField
              label="Birthday Day"
              name="day"
              type="number"
              value={memberData.birthday.day}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Birthday Month"
              name="month"
              type="number"
              value={memberData.birthday.month}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Preferred Communication Method</InputLabel>
              <Select
                name="preferredCommunicationMethod"
                value={memberData.preferredCommunicationMethod}
                onChange={handleChange}
                label="Preferred Communication Method"
              >
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="phone">Phone</MenuItem>
                <MenuItem value="text">Text</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Social Media Link Name"
              name="socialMediaLinkName"
              value={
                memberData.socialMediaLinks.length > 0
                  ? memberData.socialMediaLinks[0].linkName
                  : ""
              }
              onChange={(e) => {
                const updatedLinks = [...memberData.socialMediaLinks];
                updatedLinks[0] = {
                  ...updatedLinks[0],
                  linkName: e.target.value,
                };
                setMemberData({
                  ...memberData,
                  socialMediaLinks: updatedLinks,
                });
              }}
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Social Media Link URL"
              name="socialMediaLinkUrl"
              value={
                memberData.socialMediaLinks.length > 0
                  ? memberData.socialMediaLinks[0].linkUrl
                  : ""
              }
              onChange={(e) => {
                const updatedLinks = [...memberData.socialMediaLinks];
                updatedLinks[0] = {
                  ...updatedLinks[0],
                  linkUrl: e.target.value,
                };
                setMemberData({
                  ...memberData,
                  socialMediaLinks: updatedLinks,
                });
              }}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Other Link Name"
              name="otherLinkName"
              value={
                memberData.otherLinks.length > 0
                  ? memberData.otherLinks[0].linkName
                  : ""
              }
              onChange={(e) => {
                const updatedLinks = [...memberData.otherLinks];
                updatedLinks[0] = {
                  ...updatedLinks[0],
                  linkName: e.target.value,
                };
                setMemberData({ ...memberData, otherLinks: updatedLinks });
              }}
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Other Link URL"
              name="otherLinkUrl"
              value={
                memberData.otherLinks.length > 0
                  ? memberData.otherLinks[0].linkUrl
                  : ""
              }
              onChange={(e) => {
                const updatedLinks = [...memberData.otherLinks];
                updatedLinks[0] = {
                  ...updatedLinks[0],
                  linkUrl: e.target.value,
                };
                setMemberData({ ...memberData, otherLinks: updatedLinks });
              }}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Willing to Do Duty</FormLabel>
              <RadioGroup
                name="willingToDoDuty"
                value={memberData.willingToDoDuty ? "yes" : "no"}
                onChange={(e) =>
                  setMemberData({
                    ...memberData,
                    willingToDoDuty: e.target.value === "yes",
                  })
                }
                row
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Office</InputLabel>
              <Select
                value={
                  memberData &&
                  memberData.officeLocations &&
                  memberData.officeLocations.map((office) => office.officeName)
                }
                onChange={(event) => {
                  const selectedOffice = dutyScheduleOfficeLocations?.find(
                    (office) => office.officeName === event.target.value
                  );
                  handleOfficeChange(
                    selectedOffice.officeName,
                    selectedOffice.location
                  );
                }}
                label="Office"
              >
                {dutyScheduleOfficeLocations &&
                  dutyScheduleOfficeLocations?.map((office) => (
                    <MenuItem
                      key={office?.officeName}
                      value={office?.officeName}
                    >
                      <Checkbox
                        checked={
                          memberData &&
                          memberData.officeLocations &&
                          memberData.officeLocations?.some(
                            (o) => o.officeName === office.officeName
                          )
                        }
                      />
                      <ListItemText primary={office?.officeName} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Available Days</InputLabel>
              <Select
                multiple
                value={selectedDays}
                onChange={handleDaysChange}
                renderValue={(selected) => selected.join(", ")}
                helpertext="Select the days (e.g., Monday, Tuesday, Wednesday, Thursday, Friday)"
              >
                {daysOfWeek.map((day) => (
                  <MenuItem key={day} value={day}>
                    <Checkbox checked={selectedDays.indexOf(day) > -1} />
                    <ListItemText primary={day} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TimeSlotSelector
              onAddTimeSlot={handleAddTimeSlot}
              onRemoveTimeSlot={handleRemoveTimeSlot}
              timeSlots={timeSlots}
            />
          </Grid>
          <Grid item xs={12}>
            <UnavailablePeriodSelector
              onAddUnavailablePeriod={handleAddUnavailablePeriod}
              onRemoveUnavailablePeriod={handleRemoveUnavailablePeriod}
              unavailablePeriods={memberData?.unavailablePeriods}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className="member-profile-edit-form-actions"
          spacing={1}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className="actions-button"
          >
            Save Changes
          </Button>
        </Grid>
      </Grid>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </div>
  );
};

export default EditMemberProfile;
