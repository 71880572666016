import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const listingApi = createApi({
  reducerPath: "listing",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/listings/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Listing"],
  endpoints: (builder) => ({
    getAllListings: builder.query({
      query: () => ({
        url: "get-all",
        method: "GET",
      }),
      providesTags: [{ type: "Listing", id: "LIST" }],
    }),
    getAllListingsByTier: builder.query({
      query: (tierId) => ({
        url: `get-all-by-tier/${tierId}`,
        method: "GET",
      }),
      skip: (tierId) => !tierId,
      providesTags: [{ type: "Listing", id: "LIST" }],
    }),
    getAllListingsByListingTour: builder.query({
      query: ({ tierId, listingTourId }) => ({
        url: `get-all-by-listing-tour/${tierId}/${listingTourId}`,
        method: "GET",
      }),
      skip: (tierId, listingTourId) => !tierId || !listingTourId,
      providesTags: [{ type: "Listing", id: "LIST" }],
    }),
    getListingById: builder.query({
      query: (listingId) => ({
        url: `get-one-by-id/${listingId}`,
        method: "GET",
      }),
      skip: (listingId) => !listingId,
      providesTags: [{ type: "Listing", id: "LIST" }],
    }),
    createListing: builder.mutation({
      query: ({ tierId, agentId, formData }) => ({
        url: `create-one/${tierId}/${agentId}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [{ type: "Listing", id: "LIST" }],
    }),
    updateListing: builder.mutation({
      query: ({ tierId, listingId, formData }) => ({
        url: `update-one/${tierId}/${listingId}`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: [{ type: "Listing", id: "LIST" }],
    }),
    deleteListing: builder.mutation({
      query: ({ listingId }) => ({
        url: `delete-one/${listingId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Listing", id: "LIST" }],
    }),
    deleteAllListings: builder.mutation({
      query: () => ({
        url: `delete-all`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Listing", id: "LIST" }],
    }),
    downloadPhotograph: builder.query({
      query: ({ photographId, disposition }) =>
        `download-photograph/${photographId}?disposition=${disposition}`,
      skip: (photographId, disposition) => !photographId || !disposition,
    }),
    useLazyDownloadPhotographQuery: builder.query({
      query: ({ photographId, disposition }) => ({
        url: `download-photograph/${photographId}?disposition=${disposition}`,
        method: "GET",
      }),
      skip: (photographId, disposition) => !photographId || !disposition,
    }),
    getListingsByNextListingTour: builder.query({
      query: (tierId) => ({
        url: `get-all-by-next-listing-tour/${tierId}`,
        method: "GET",
      }),
      skip: (tierId) => !tierId,
      providesTags: [{ type: "Listing", id: "LIST" }],
    }),
    getAgentListingsByTier: builder.query({
      query: ({ tierId, agentId }) => ({
        url: `get-agent-listings-by-tier/${tierId}/${agentId}`,
        method: "GET",
      }),
      skip: (tierId, agentId) => !tierId || !agentId,
      providesTags: [{ type: "Listing", id: "LIST" }],
    }),
    getComingListingsByTier: builder.query({
      query: (tierId) => ({
        url: `get-coming-listings-by-tier/${tierId}`,
        method: "GET",
      }),
      skip: (tierId) => !tierId,
      providesTags: [{ type: "Listing", id: "LIST" }],
    }),
  }),
});

export const {
  useGetAllListingsQuery,
  useGetAllListingsByTierQuery,
  useGetAllListingsByListingTourQuery,
  useGetListingByIdQuery,
  useCreateListingMutation,
  useUpdateListingMutation,
  useDeleteListingMutation,
  useDeleteAllListingsMutation,
  useDownloadPhotographQuery,
  useLazyDownloadPhotographQuery,
  useGetListingsByNextListingTourQuery,
  useGetAgentListingsByTierQuery,
  useGetComingListingsByTierQuery,
} = listingApi;

export default listingApi;
export { listingApi };
