import React, { useEffect, useState } from "react";
import { useGetAllListingToursByTierQuery } from "../../../services/listing-tours";
import { Tabs, Tab, Paper, Typography } from "@mui/material";
import { TabPanel } from "../../helpers/utils";
import ListingTourTabs from "./ListingTourTabs";
import NotificationSnackbar from "../../helpers/notification-snackbar";

function ListingTourMainTabs({ tier }) {
  const { data: listingToursData } = useGetAllListingToursByTierQuery(
    tier?._id,
    {
      skip: !tier?._id,
    }
  );

  const [selectedTab, setSelectedTab] = useState("upcoming");
  const [upcomingTours, setUpcomingTours] = useState([]);
  const [pastTours, setPastTours] = useState([]);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (listingToursData) {
      setUpcomingTours(listingToursData.upcomingTours || []);
      setPastTours(listingToursData.pastTours || []);
    }
  }, [listingToursData]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Paper elevation={4} sx={{ width: "100%", p: "1rem" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
          aria-label="listing tour tabs"
        >
          <Tab label="Upcoming Tours" value="upcoming" />
          <Tab label="Past Tours" value="past" />
        </Tabs>
        <TabPanel value={selectedTab} index="upcoming">
          {upcomingTours?.length > 0 ? (
            <ListingTourTabs listingTours={upcomingTours} />
          ) : (
            <Typography>No upcoming tours available</Typography>
          )}
        </TabPanel>

        <TabPanel value={selectedTab} index="past">
          {pastTours?.length > 0 ? (
            <ListingTourTabs listingTours={pastTours} />
          ) : (
            <Typography>No past tours available</Typography>
          )}
        </TabPanel>
      </Paper>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </div>
  );
}

export default ListingTourMainTabs;
