import React, { useState, memo } from "react";
import { Paper, Tabs, Tab } from "@mui/material";
import { TabPanel } from "../../helpers/utils";
import ListingTourList from "./ListingTourList";
import ListingTourItemList from "./ListingList";

function ListingTourManagementTabs() {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper className="tabs-paper-brokerage-ecosys">
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="brokerage tabs"
        variant="scrollable"
        scrollButtons="auto"
        className="tabs-paper"
      >
        <Tab
          label="Listing Tour"
          id="simple-tab-2"
          aria-controls="simple-tabpanel-2"
        />
        <Tab
          label="Listing Tour Item"
          id="simple-tab-2"
          aria-controls="simple-tabpanel-2"
        />
      </Tabs>
      <TabPanel value={tabValue} index={0} className="brokerage-tabs-paper">
        <ListingTourList />
      </TabPanel>
      <TabPanel value={tabValue} index={1} className="brokerage-tabs-paper">
        <ListingTourItemList />
      </TabPanel>
    </Paper>
  );
}

export default memo(ListingTourManagementTabs);
