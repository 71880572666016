import React, { useState } from "react";
import { Container, Typography, Paper, Box, Button } from "@mui/material";
import ListingTourMainTabs from "./ListingTourMainTabs";
import CreateEditListingTourDialog from "./CreateEditListingTourDialog";

const ListingTourManager = ({ tier }) => {
  const [createListingTourDialogOpen, setCreateListingTourDialogOpen] =
    useState(false);

  const handleCreateListingTourDialogOpen = () => {
    setCreateListingTourDialogOpen(true);
  };

  const handleCreateListingTourDialogClose = () => {
    setCreateListingTourDialogOpen(false);
  };

  return (
    <Container>
      <Paper
        sx={{
          p: 2,
          mb: 3,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            p: 1,
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: 700, width: "50%" }}
            fontWeight={700}
          >
            Listing Tour Manager
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateListingTourDialogOpen}
          >
            Create New Listing Tour
          </Button>
        </Box>
      </Paper>
      <ListingTourMainTabs tier={tier} />
      <CreateEditListingTourDialog
        tier={tier}
        open={createListingTourDialogOpen}
        onClose={handleCreateListingTourDialogClose}
        mode="create"
      />
    </Container>
  );
};

export default ListingTourManager;
