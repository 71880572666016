// Brokerage Admin Form
import React, { useState, useEffect, useId, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Autocomplete,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Info, Close } from "@mui/icons-material/";
import {
  useCreateListingMutation,
  useUpdateListingMutation,
} from "../../../services/listings";
import { useValidateAddressQuery } from "../../../services/maps-api";
import { useGetAllTierMembersQuery } from "../../../services/member";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { areaSubareaMap } from "../../helpers/AreaSubArea";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
dayjs.extend(utc);

const ACRE_IN_SQFT = 43560;

function CreateEditListingForm({
  open,
  onClose,
  mode = "create",
  tierId,
  listing,
  listingTours = [],
}) {
  const editableFields = [
    "listingTour",
    "address",
    "listPrice",
    "listingDate",
    "isForListingTour",
    "area",
    "subarea",
    "mls",
    "totalSqft",
    "finishedSqft",
    "lotSqft",
    "lotAcres",
    "bedrooms",
    "bathrooms",
    "listingComment",
    "lockboxLocation",
    "videoLinks",
  ];

  const fieldLabelsHash = {
    address: { label: "Address", width: 12, type: "text" },
    listPrice: {
      label: "List Price",
      width: 6,
      type: "number",
      formatting: "currency",
    },
    listingDate: { label: "Listing Date", width: 6, type: "text" },
    isForListingTour: { label: "Is For Listing Tour", width: 6, type: "text" },
    area: { label: "Area", width: 6, type: "text" },
    subarea: { label: "Subarea", width: 6, type: "text" },
    mls: { label: "MLS Number", width: 12, type: "text" },
    totalSqft: { label: "Total Square Feet", width: 6, type: "number" },
    finishedSqft: { label: "Finished Square Feet", width: 6, type: "number" },
    lotSqft: { label: "Lot Square Feet", width: 6, type: "number" },
    lotAcres: { label: "Lot Acres", width: 6, type: "number" },
    bedrooms: { label: "Bedrooms", width: 6, type: "number" },
    bathrooms: { label: "Bathrooms", width: 6, type: "number" },
    listingComment: { label: "Listing Comment", width: 12, type: "text" },
    lockboxLocation: { label: "Lockbox Location", width: 12, type: "text" },
    videoLinks: { label: "Video Link", width: 12, type: "text" },
  };

  const [formValues, setFormValues] = useState(() => ({
    address: "",
    listPrice: "",
    listingDate: "",
    bedrooms: "",
    bathrooms: "",
    houseSqft: "",
    mls: "",
    area: "",
    subarea: "",
    totalSqft: "",
    finishedSqft: "",
    lotSqft: "",
    lotAcres: "",
    listingComment: [],
    openTimeStart: null,
    openTimeEnd: null,
    lockboxLocation: "",
    videoLinks: [],
    listingTour: null,
    isForListingTour: false,
  }));

  const { userId: loggedInUserId } = useContext(AuthContext);

  const [selectedAgent, setSelectedAgent] = useState(null);
  const [createListing, { isLoading: isCreating }] = useCreateListingMutation();
  const [updateListing, { isLoading: isUpdating }] = useUpdateListingMutation();
  const { data: members, isLoading: isMembersLoading } =
    useGetAllTierMembersQuery({ tierId }, { skip: !tierId });
  const [isAddressValid, setIsAddressValid] = useState(null);
  const [addressOptionsResults, setAddressOptionsResults] = useState([]);
  const [isAddressFormatted, setIsAddressFormatted] = useState(false);
  const [photographs, setPhotographs] = useState([]);
  const [existingPhotographs, setExistingPhotographs] = useState(
    listing?.photographs || []
  );
  const [deletedPhotographIds, setDeletedPhotographIds] = useState([]);
  const photographsEditInputId = useId();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (members) {
      const foundAgent = members?.find(
        (member) => member?.user?._id === loggedInUserId
      );

      setSelectedAgent(foundAgent || null);
    }
  }, [members, loggedInUserId]);

  useEffect(() => {
    if (listing) {
      const [startTime, endTime] = listing.openTime
        ? listing?.openTime?.split(" - ")
        : [null, null];

      const updatedFormValues = {
        address: listing.address || "",
        listPrice: listing.listPrice || "",
        listingDate: listing.listingDate ? dayjs(listing.listingDate) : null,
        bedrooms: listing.bedrooms || "",
        bathrooms: listing.bathrooms || "",
        mls: listing.mls || "",
        area: listing.area || "",
        subarea: listing.subarea || "",
        totalSqft: listing.totalSqft || "",
        finishedSqft: listing.finishedSqft || "",
        lotSqft: listing.lotSqft || "",
        lotAcres: listing.lotAcres || "",
        listingComment: listing.listingComment || [],
        lockboxLocation: listing.lockboxLocation || "",
        videoLinks: listing.videoLinks || [],
        openTimeStart: startTime ? dayjs(startTime, "hh:mm a") : null,
        openTimeEnd: endTime ? dayjs(endTime, "hh:mm a") : null,
        listingTour: listing.listingTour || null,
        isForListingTour: listing.isForListingTour || false,
      };

      setFormValues(updatedFormValues);
      setSelectedAgent(listing?.agent || null);
      setExistingPhotographs(listing?.photographs || []);
    } else {
      const nextUpcomingTour = listingTours
        ?.filter((tour) => new Date(tour.listingTourDate) >= new Date())
        ?.sort(
          (a, b) => new Date(a.listingTourDate) - new Date(b.listingTourDate)
        )[0];

      setFormValues({
        address: "",
        listPrice: "",
        listingDate: null,
        bedrooms: "",
        bathrooms: "",
        houseSqft: "",
        mls: "",
        area: "",
        subarea: "",
        totalSqft: "",
        finishedSqft: "",
        lotSqft: "",
        lotAcres: "",
        listingComment: [],
        lockboxLocation: "",
        videoLinks: [],
        openTimeStart: null,
        openTimeEnd: null,
        listingTour: nextUpcomingTour ? nextUpcomingTour : null,
      });
    }
  }, [listing]);

  const { data: addressOptions, isFetching: isAddressOptionsLoading } =
    useValidateAddressQuery(
      { tierId, address: formValues.address },
      {
        skip: !tierId || !formValues.address || formValues.address?.length < 3,
      }
    );

  useEffect(() => {
    if (
      addressOptions &&
      addressOptions.status === "OK" &&
      addressOptions.results.length > 0
    ) {
      setAddressOptionsResults(addressOptions?.results);
      setIsAddressValid(true);
    } else {
      setAddressOptionsResults([]);
    }
  }, [addressOptions?.status, addressOptions?.results]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const fieldInfo = fieldLabelsHash[name];

    let sanitizedValue = value;

    if (fieldInfo.type === "number") {
      if (fieldInfo.formatting === "currency") {
        sanitizedValue = value.replace(/[^0-9]/g, "");
      }
      sanitizedValue = sanitizedValue ? parseFloat(sanitizedValue) : "";
    }

    const updatedValues = { ...formValues, [name]: sanitizedValue };

    if (name === "lotSqft" && sanitizedValue) {
      updatedValues.lotAcres = (sanitizedValue / ACRE_IN_SQFT).toFixed(2);
    } else if (name === "lotAcres" && sanitizedValue) {
      updatedValues.lotSqft = (sanitizedValue * ACRE_IN_SQFT).toFixed(0);
    }

    setFormValues(updatedValues);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formValues.address ||
      !formValues.listingDate ||
      !formValues.address.trim() ||
      !formValues.bedrooms ||
      !formValues.bathrooms ||
      !formValues.area ||
      !formValues.subarea ||
      !selectedAgent
    ) {
      setNotification({
        open: true,
        message:
          "Please fill in all required fields correctly, including a valid formatted address.",
      });
      return;
    }
    const formData = new FormData();

    photographs.forEach((file) => {
      formData.append("photographs", file);
    });

    deletedPhotographIds.forEach((id) => {
      formData.append("deletedPhotographIds[]", id);
    });

    const formattedOpenTime = `${formValues?.openTimeStart?.format(
      "hh:mm a"
    )} - ${formValues?.openTimeEnd?.format("hh:mm a")}`;

    const submissionData = {
      address: formValues.address,
      listPrice: formValues.listPrice,
      listingDate: formValues.listingDate ? formValues.listingDate : null,
      bedrooms: formValues.bedrooms,
      bathrooms: formValues.bathrooms,
      mls: formValues.mls,
      area: formValues.area,
      subarea: formValues.subarea,
      totalSqft: formValues.totalSqft,
      finishedSqft: formValues.finishedSqft,
      lotSqft: formValues.lotSqft,
      lotAcres: formValues.lotAcres,
      listingComment: formValues.listingComment,
      lockboxLocation: formValues.lockboxLocation,
      videoLinks: formValues.videoLinks,
      openTime: formattedOpenTime,
      listingTour: formValues.listingTour ? formValues.listingTour._id : null,
      isForListingTour: formValues.isForListingTour,
    };

    formData.append("listingData", JSON.stringify(submissionData));

    let result;
    if (mode === "edit" && listing?._id) {
      result = await updateListing({
        tierId,
        listingId: listing?._id,
        formData,
      });
    } else if (mode === "create") {
      result = await createListing({
        tierId,
        agentId: selectedAgent?.user?._id,
        formData,
      });
    }
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        if (mode === "create") {
          setFormValues({
            address: "",
            listPrice: "",
            listingDate: null,
            bedrooms: "",
            bathrooms: "",
            houseSqft: "",
            mls: "",
            area: "",
            subarea: "",
            totalSqft: "",
            finishedSqft: "",
            lotSqft: "",
            lotAcres: "",
            listingComment: [],
            openTimeStart: null,
            openTimeEnd: null,
            lockboxLocation: "",
            videoLinks: [],
          });
        }
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving listing: ${
          result?.error?.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const tomorrow = dayjs().startOf("tomorrow");

  const handleListingDateChange = (newValue) => {
    if (newValue && newValue.isBefore(tomorrow)) {
      setNotification({
        open: true,
        message: "The date is in the past.",
      });
    }
    setNotification({
      open: false,
      message: "",
    });

    setFormValues({
      ...formValues,
      listingDate: dayjs(newValue || new Date()),
    });
  };

  const handleAddressInput = (event, newInputValue) => {
    setFormValues((prev) => ({
      ...prev,
      address: newInputValue,
    }));
    setIsAddressFormatted(false);
  };

  const handleAddressSelect = (event, newValue) => {
    if (
      newValue &&
      typeof newValue === "object" &&
      newValue?.formatted_address
    ) {
      setFormValues((prev) => ({
        ...prev,
        address: newValue?.formatted_address,
      }));
      setIsAddressFormatted(true);
      setIsAddressValid(true);
      setAddressOptionsResults([]);
    } else if (typeof newValue === "string") {
      setFormValues((prev) => ({
        ...prev,
        address: newValue,
      }));
      setIsAddressFormatted(false);
    } else {
      setIsAddressFormatted(false);
    }
  };

  const handleListingCommentChange = (index, value) => {
    setFormValues((prev) => {
      const newComments = [...prev.listingComment];
      newComments[index] = value;
      return { ...prev, listingComment: newComments };
    });
  };

  const addListingCommentField = () => {
    setFormValues((prev) => ({
      ...prev,
      listingComment: [...prev.listingComment, ""],
    }));
  };

  const removeListingCommentField = (index) => {
    setFormValues((prev) => {
      const newComments = [...prev.listingComment];
      newComments.splice(index, 1);
      return { ...prev, listingComment: newComments };
    });
  };

  const handleNewPhotographChange = (event) => {
    const filesArray = Array.from(event.target.files);
    if (
      existingPhotographs.length + photographs.length + filesArray.length >
      10
    ) {
      setNotification({
        open: true,
        message: "You can upload up to 10 photographs.",
      });
      return;
    }

    if (filesArray) {
      setPhotographs((prev) => [...prev, ...filesArray]);
    }
  };

  const handleDeleteExistingPhotograph = (photographId) => {
    setDeletedPhotographIds((prev) => [...prev, photographId]);
    setExistingPhotographs((prev) =>
      prev.filter((att) => att._id !== photographId)
    );
  };

  const handleRemoveNewPhotograph = (indexToRemove) => {
    setPhotographs((currentFiles) =>
      currentFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle
        sx={{
          backgroundColor: "#1976d2",
          color: "white",
          marginBottom: "1rem",
        }}
      >
        <Typography align="center" gutterBottom>
          {listing ? "Edit Listing" : "Create New Listing"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Autocomplete
                options={members || []}
                getOptionLabel={(option) =>
                  `${option?.user?.firstName} ${option?.user?.lastName} (${option?.user?.email})`
                }
                value={selectedAgent}
                onChange={(event, newValue) => setSelectedAgent(newValue)}
                loading={isMembersLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Agent"
                    required
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isMembersLoading ? (
                            <CircularProgress size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label htmlFor={photographsEditInputId}>
                  <Button
                    variant="contained"
                    component="span"
                    sx={{ textTransform: "none", mb: 2 }}
                  >
                    {`${
                      photographs && photographs?.length > 0
                        ? "Add More"
                        : "Upload"
                    } Photographs (Optional)`}
                  </Button>
                  <input
                    id={photographsEditInputId}
                    type="file"
                    hidden
                    multiple
                    accept=".jpg,.png,.jpeg"
                    onChange={(event) => handleNewPhotographChange(event)}
                  />
                </label>
                <Box sx={{ mt: 2 }}>
                  {existingPhotographs && existingPhotographs?.length > 0 && (
                    <Typography variant="body2">
                      Existing Photographs:
                    </Typography>
                  )}
                  {existingPhotographs &&
                    existingPhotographs?.map((photograph, index) => {
                      const urlParts =
                        photograph?.url && photograph?.url?.split("/");
                      const photographName = urlParts[urlParts.length - 1];

                      return (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mb: 1,
                          }}
                        >
                          <Typography variant="body2">
                            {photographName}
                          </Typography>
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleDeleteExistingPhotograph(photograph?._id)
                            }
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        </Box>
                      );
                    })}
                  {photographs && photographs?.length > 0 && (
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      New Photographs:
                    </Typography>
                  )}
                  {photographs &&
                    photographs?.map((file, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          mb: 1,
                        }}
                      >
                        <Typography variant="body2">{file.name}</Typography>
                        <IconButton
                          size="small"
                          onClick={() => handleRemoveNewPhotograph(index)}
                        >
                          <Close fontSize="small" />
                        </IconButton>
                      </Box>
                    ))}
                </Box>
                <Tooltip title="Allowed image types: jpg, png, jpeg.">
                  <IconButton>
                    <Info fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            {editableFields.map((key) => {
              if (key === "address") {
                return (
                  <Grid item xs={12} key={key}>
                    <Autocomplete
                      freeSolo
                      options={addressOptionsResults || []}
                      getOptionLabel={(option) =>
                        typeof option === "string"
                          ? option
                          : option?.formatted_address || ""
                      }
                      value={formValues.address}
                      onInputChange={handleAddressInput}
                      onChange={handleAddressSelect}
                      loading={isAddressOptionsLoading}
                      filterOptions={(options) => options}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Address"
                          variant="outlined"
                          fullWidth
                          error={!isAddressValid}
                          placeholder="Enter a valid address (Street, City,
                    Prov/State)"
                          helperText="Address format: Number Street,
                    City, Prov/State - ex: 123 Main St, Vancouver, BC"
                        />
                      )}
                    />
                  </Grid>
                );
              } else if (key === "listingDate") {
                return (
                  <Grid item xs={6} key={key}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Listing Date"
                          value={dayjs(formValues.listingDate)}
                          onChange={handleListingDateChange}
                          format="LL"
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                );
              } else if (key === "listingTour") {
                return (
                  <Grid item xs={12} key={key}>
                    <InputLabel id="listingTour-select-label">
                      Listing Tour
                    </InputLabel>
                    <Select
                      labelId="listingTour-select-label"
                      id="listingTour-select"
                      label="Listing Tour"
                      name="listingTour"
                      value={formValues?.listingTour?._id || ""}
                      onChange={(event) =>
                        setFormValues((prev) => ({
                          ...prev,
                          listingTour:
                            listingTours.find(
                              (tour) => tour._id === event.target.value
                            ) || null,
                        }))
                      }
                      fullWidth
                      variant="outlined"
                    >
                      <MenuItem value="">No Tour | Not for Tour</MenuItem>
                      {listingTours?.map((tour) => (
                        <MenuItem key={tour._id} value={tour._id}>
                          {`${tour.listingTourTitle} - ${new Date(
                            tour.listingTourDate
                          ).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                );
              } else if (key === "area") {
                return (
                  <Grid item xs={6} key={key}>
                    <InputLabel id="area-select-label">Area</InputLabel>
                    <Select
                      labelId="area-select-label"
                      id="area-select"
                      label="Area"
                      name="area"
                      value={formValues?.area || ""}
                      onChange={(event) =>
                        setFormValues((prev) => ({
                          ...prev,
                          area: event.target.value,
                          subarea: "",
                        }))
                      }
                      fullWidth
                      variant="outlined"
                      required
                    >
                      {Object.keys(areaSubareaMap)?.map((area) => (
                        <MenuItem key={area} value={area}>
                          {area}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                );
              } else if (key === "subarea") {
                return (
                  <Grid item xs={6} key={key}>
                    <InputLabel id="subarea-select-label">Subarea</InputLabel>
                    <Select
                      labelId="subarea-select-label"
                      id="subarea-select"
                      label="Subarea"
                      name="Subarea"
                      value={formValues?.subarea || ""}
                      onChange={(event) =>
                        setFormValues((prev) => ({
                          ...prev,
                          subarea: event.target.value,
                        }))
                      }
                      fullWidth
                      variant="outlined"
                      required
                      disabled={!formValues.area}
                    >
                      {formValues?.area &&
                        areaSubareaMap[formValues?.area]?.map((subarea) => (
                          <MenuItem key={subarea?.value} value={subarea?.value}>
                            {subarea?.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                );
              } else if (key === "openTimeStart") {
                return (
                  <Grid item xs={12} sm={6} key={key}>
                    <TimePicker
                      label="Open Time Start"
                      value={formValues.openTimeStart}
                      onChange={(newValue) =>
                        setFormValues((prev) => ({
                          ...prev,
                          openTimeStart: newValue,
                        }))
                      }
                      renderInput={(params) => (
                        <TextField {...params} fullWidth required />
                      )}
                    />
                  </Grid>
                );
              } else if (key === "openTimeEnd") {
                return (
                  <Grid item xs={12} sm={6} key={key}>
                    <TimePicker
                      label="Open Time End"
                      value={formValues.openTimeEnd}
                      onChange={(newValue) =>
                        setFormValues((prev) => ({
                          ...prev,
                          openTimeEnd: newValue,
                        }))
                      }
                      renderInput={(params) => (
                        <TextField {...params} fullWidth required />
                      )}
                    />
                  </Grid>
                );
              } else if (key === "listingComment") {
                return (
                  <Grid item xs={12} key={key}>
                    <Typography variant="h6" gutterBottom>
                      Listing Comments
                    </Typography>
                    {formValues.listingComment?.map((comment, index) => (
                      <div
                        key={index}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <TextField
                          name={`listingComment-${index}`}
                          label={`Comment ${index + 1}`}
                          fullWidth
                          value={comment}
                          onChange={(e) =>
                            handleListingCommentChange(index, e.target.value)
                          }
                          multiline
                          rows={2}
                          style={{ marginBottom: "8px" }}
                        />
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => removeListingCommentField(index)}
                          style={{ marginLeft: "8px", height: "56px" }}
                        >
                          Remove
                        </Button>
                      </div>
                    ))}
                    <Button
                      variant="contained"
                      onClick={addListingCommentField}
                      style={{ marginTop: "8px" }}
                    >
                      Add
                    </Button>
                  </Grid>
                );
              } else if (key === "isForListingTour") {
                return (
                  <Grid item xs={12} key={key}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formValues.isForListingTour}
                          onChange={(e) =>
                            setFormValues((prevValues) => ({
                              ...prevValues,
                              isForListingTour: e.target.checked,
                            }))
                          }
                          name="isForListingTour"
                          color="primary"
                        />
                      }
                      label="Include on Listing Tour"
                    />
                  </Grid>
                );
              }
              return (
                <Grid item xs={12} sm={6} key={key}>
                  <TextField
                    name={key}
                    label={fieldLabelsHash[key].label}
                    fullWidth
                    value={formValues[key]}
                    onChange={handleChange}
                    required={[
                      "address",
                      "listPrice",
                      "listingDate",
                      "bedrooms",
                      "bathrooms",
                      "area",
                      "subarea",
                    ].includes(key)}
                    type={fieldLabelsHash[key].type}
                    placeholder={` Enter ${fieldLabelsHash[key]?.label}`}
                    multiline={key === "comments"}
                    rows={key === "comments" ? 4 : 1}
                    InputProps={{
                      startAdornment:
                        fieldLabelsHash[key]?.formatting === "currency"
                          ? "$"
                          : null,
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Button onClick={onClose} color="secondary" type="submit" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isCreating || isUpdating || !isAddressFormatted}
          >
            {isCreating || isUpdating ? (
              <CircularProgress size={24} />
            ) : listing ? (
              "Update Listing"
            ) : (
              "Create Listing"
            )}
          </Button>
        </Grid>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
}

export default CreateEditListingForm;
