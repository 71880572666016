import React, { useState, useContext } from "react";
import { useGetAllRolesQuery } from "../../../services/role";
import { AuthContext } from "../../../context/AuthContext";
import {
  Container,
  Typography,
  Paper,
  Tabs,
  Tab,
  List,
  ListItem,
  Divider,
  Grid,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import RolesList from "./RolesList";
import CreateEditRoleForm from "./CreateEditRoleForm";
import AssignRoleToUserDialog from "./AssignRoleToUserDialog";
import { TabPanel } from "../../helpers/utils";
import RoleNameEnumManager from "./RoleNameEnumManager";
import RolePermissionManager from "./RolePermissionManager";

const RoleManagement = () => {
  const { accountType, userId } = useContext(AuthContext);
  const { data: roles } = useGetAllRolesQuery(accountType, {
    skip: !userId && accountType !== "reosadmin",
  });
  const [openAssignRoleToUserDialog, setOpenAssignRoleToUserDialog] =
    useState(false);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [openCreateRoleDialog, setOpenCreateRoleDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOpenAssignRoleToUserDialog = () => {
    setOpenAssignRoleToUserDialog(true);
  };

  const handleCloseAssignRoleToUserDialog = () => {
    setOpenAssignRoleToUserDialog(false);
  };

  const handleOpenCreateRoleDialog = () => {
    setOpenCreateRoleDialog(true);
  };

  const handleCloseCreateRoleDialog = () => {
    setOpenCreateRoleDialog(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (accountType !== "reosadmin") {
    return (
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        You are not allowed for this page
      </Typography>
    );
  }

  return (
    <Container className="management-page">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Role Management
        </Typography>
        <Divider />
        <Grid container>
          <Grid item xs={6} sx={{ p: 2 }}>
            <List style={{ width: "100%", marginRight: "0.1rem" }}>
              <ListItem
                onClick={handleOpenCreateRoleDialog}
                className="user-management-item"
              >
                Create new Role
              </ListItem>
              <Divider />
              <ListItem
                onClick={handleOpenAssignRoleToUserDialog}
                className="user-management-item"
              >
                Assign Role to a User
              </ListItem>
              <Divider />
            </List>
          </Grid>
        </Grid>
      </Paper>
      <Paper>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="roles tabs"
          variant="scrollable"
          scrollButtons="auto"
          className="tabs-paper"
        >
          <Tab
            label="Roles List"
            id="simple-tab-0"
            aria-controls="simple-tabpanel-0"
          />
          <Tab
            label="Role Name Enums"
            id="simple-tab-1"
            aria-controls="simple-tabpanel-1"
          />
          <Tab
            label="Role Permission Enums"
            id="simple-tab-1"
            aria-controls="simple-tabpanel-1"
          />
        </Tabs>
        <TabPanel value={tabValue} index={0} className="tabs-paper">
          <RolesList roles={roles} />
        </TabPanel>
        <TabPanel value={tabValue} index={1} className="tabs-paper">
          <RoleNameEnumManager />
        </TabPanel>
        <TabPanel value={tabValue} index={2} className="tabs-paper">
          <RolePermissionManager />
        </TabPanel>
      </Paper>
      <CreateEditRoleForm
        mode="create"
        open={openCreateRoleDialog}
        onClose={handleCloseCreateRoleDialog}
      />
      <AssignRoleToUserDialog
        open={openAssignRoleToUserDialog}
        onClose={handleCloseAssignRoleToUserDialog}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Container>
  );
};

export default RoleManagement;
