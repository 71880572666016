import React, { useState } from "react";
import {
  useGetAllListingsQuery,
  useDeleteAllListingsMutation,
} from "../../../services/listings";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import CreateEditListingForm from "./CreateEditListingForm";
import NotificationSnackbar from "../../helpers/notification-snackbar";

function ListingList({ listingTour }) {
  const { data: listings } = useGetAllListingsQuery();
  const [deleteAllListings, { isLoading: isDeleteAllListingsLoading }] =
    useDeleteAllListingsMutation();

  const [createListingDialogOpen, setCreateListingDialogOpen] = useState(false);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleCreateListingDialogOpen = () => {
    setCreateListingDialogOpen(true);
  };

  const handleCreateListingDialogClose = () => {
    setCreateListingDialogOpen(false);
  };

  const handleDeleteAllListings = async () => {
    const result = await deleteAllListings();
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `Error Deletinge All Listing Tour Items. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <div>
      <Paper sx={{ p: 1, mb: 2, display: "flex", flexDirection: "column" }}>
        <Typography variant="h6" align="center" gutterBottom>
          Listing Tour Items List
        </Typography>
        <Divider />
        <Box
          sx={{ p: 0, mt: 1, display: "flex", justifyContent: "space-between" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateListingDialogOpen}
          >
            Create New Listing Tour Item
          </Button>
          <Divider />
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteAllListings}
            disabled={
              !listings || listings?.length === 0 || isDeleteAllListingsLoading
            }
          >
            {isDeleteAllListingsLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Delete All Listing Tour Items"
            )}
          </Button>
        </Box>
      </Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>List Price</TableCell>
              <TableCell>Bedrooms</TableCell>
              <TableCell>Bathrooms</TableCell>
              {/* <TableCell>House Size (sqft)</TableCell> */}
              <TableCell>MLS</TableCell>
              <TableCell>MLS Zone</TableCell>
              <TableCell>Open Time</TableCell>
              <TableCell>Lockbox Location</TableCell>
              <TableCell>Agent</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>Comments</TableCell>
              <TableCell>Estimated Selling Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listings &&
              listings.length > 0 &&
              listings?.map((listing, index) => (
                <TableRow key={`${listing?._id}-${index}`}>
                  <TableCell>{listing?.address}</TableCell>
                  <TableCell>${listing?.listPrice?.toLocaleString()}</TableCell>
                  <TableCell>{listing?.bedrooms}</TableCell>
                  <TableCell>{listing?.bathrooms}</TableCell>
                  <TableCell>{listing?.houseSqft}</TableCell>
                  <TableCell>{listing?.mls}</TableCell>
                  <TableCell>{listing?.mlsZone}</TableCell>
                  <TableCell>{listing?.openTime}</TableCell>
                  <TableCell>{listing?.lockboxLocation}</TableCell>
                  <TableCell>
                    {`${listing?.agent?.firstName} ${listing?.agent?.lastName}` ||
                      "No name"}
                  </TableCell>
                  <TableCell>{`` || "No Actions Yet"}</TableCell>
                  <TableCell sx={{ minWidth: 200, wordBreak: "break-word" }}>
                    {listing?.comments?.map((com) => (
                      <div
                        key={com?.user?._id}
                      >{`${com?.user?.firstName} ${com?.user?.lastName}: ${com?.comment}`}</div>
                    ))}
                  </TableCell>
                  <TableCell sx={{ minWidth: 200, wordBreak: "break-word" }}>
                    {listing?.estimatedSellingPrice?.map((esp) => (
                      <div
                        key={esp?.user?._id}
                      >{`${esp?.user?.firstName} ${esp?.user?.lastName}: ${esp?.estimatedPrice}`}</div>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {(!listings || listings?.length === 0) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pt: 20,
          }}
        >
          <Typography variant="h5" gutterBottom>
            No Listing Tour Items available
          </Typography>
        </Box>
      )}
      <Divider style={{ margin: "20px 0" }} />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <CreateEditListingForm
        open={createListingDialogOpen}
        onClose={handleCreateListingDialogClose}
        mode="create"
        listingTourId={listingTour?._id}
        tierId={listingTour?.tier?._id}
      />
    </div>
  );
}

export default ListingList;
