import React, { useState, useEffect, useContext, memo } from "react";
import { useLazyDownloadImageQuery } from "../../services/post";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { SidebarContext } from "../../context/SidebarContext";
import { TierContext } from "../../context/TierContext";
import EditIcon from "@mui/icons-material/Edit"; // For a pencil/edit icon
import DOMPurify from "dompurify";
import {
  Card,
  Paper,
  CardHeader,
  CardContent,
  CardMedia,
  Divider,
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Badge,
  useMediaQuery,
  Chip,
  Box,
  Link as LinkComponent,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  MoreVert,
  DeleteForever,
  Edit,
  CalendarToday,
  VideoChat,
  PinDrop,
  Visibility,
} from "@mui/icons-material/";
import {
  useAttendEventMutation,
  useUnattendEventMutation,
  useDeleteAllRecurringPostsMutation,
} from "../../services/post";
import EditPost from "./EditPost";
import PostVideos from "./PostVideos";
import NotificationSnackbar from "../helpers/notification-snackbar";
import RSVPButton from "../helpers/RSVPButton";
import MemberProfileDrawer from "../members/MemberProfileDrawer";
import { AvatarStyledBadge } from "../helpers/utils";
import Attachments from "./Attachments";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import ConfirmationDialog from "../helpers/ConfirmationDialog";
dayjs.extend(utc);
dayjs.extend(timezone);

const EventPostCard = ({
  post,
  categoryType,
  isPostList = false,
  isPostPage = false,
  isPostHomePageList = false,
  onDialogOpen,
  onMigratePostToNewCategoryDialogOpen,
  refetchPosts,
  currentUserId,
  onSelect,
  isPostsLoading,
  isPostsError,
  isNewPost,
  viewMode,
}) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const { accountType } = useContext(AuthContext);
  const { sidebarOpen } = useContext(SidebarContext);
  const { currentTierId } = useContext(TierContext);
  const [trigger, { data: downloadedImage }] = useLazyDownloadImageQuery();
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const attends = post?.attends;
  const [attendEvent] = useAttendEventMutation();
  const [unattendEvent] = useUnattendEventMutation();
  const [menuAnchorEl, setMenuAnchorEl] = useState({});
  const [openEditPostDialog, setOpenEditPostDialog] = useState(false);
  const [
    openDeleteAllRecurringPostsDialog,
    setOpenDeleteAllRecurringPostsDialog,
  ] = useState(false);
  const [
    deleteAllRecurringPosts,
    { isLoading: isDeleteAllRecurringPostsLoading },
  ] = useDeleteAllRecurringPostsMutation();

  useEffect(() => {
    if (post?.image?.url && !post?.image?.url.includes("cloudinary")) {
      trigger({ imageUrl: post?.image?.url, disposition: "attachment" });
    }
  }, [post, trigger]);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const eventDate = dayjs.utc(post?.eventDetails?.eventDate).local() || "";
  const eventDateAndTimeNotFormated =
    dayjs(post?.eventDetails?.eventDate) || "";
  const eventDateAndTime =
    eventDateAndTimeNotFormated.format("dddd, MMMM D, YYYY HH:mm") || "";

  const eventEndDateAndTimeNotFormated = post?.eventDetails?.eventEndDate
    ? dayjs(post?.eventDetails?.eventEndDate)
    : "";
  const eventEndDateAndTime = eventEndDateAndTimeNotFormated
    ? eventEndDateAndTimeNotFormated.format("dddd, MMMM D, YYYY HH:mm")
    : "";

  const formattedDateToDay = eventDate?.format("D") || "";
  const formattedDateToMonth = eventDate?.format("MMM") || "";

  const isAttendedByCurrentUser = attends?.some(
    (attend) => attend?._id === currentUserId
  );
  const [rsvpStatus, setRsvpStatus] = useState(
    isAttendedByCurrentUser ? "Going" : "RSVP"
  );
  const [anchorAttendeesEl, setAnchorAttendeesEl] = useState(null);
  const handleAttendeesPopoverOpen = (event) => {
    setAnchorAttendeesEl(event.currentTarget);
  };

  const handleAttendeesPopoverClose = () => {
    setAnchorAttendeesEl(null);
  };

  const openAttendees = Boolean(anchorAttendeesEl);

  const handleMenuOpen = (event, postId) => {
    event.stopPropagation();
    setMenuAnchorEl({ ...menuAnchorEl, [postId]: event.currentTarget });
  };

  const handleMenuClose = (postId) => {
    setMenuAnchorEl({ ...menuAnchorEl, [postId]: null });
  };

  const handleOpenEditPostDialog = (event) => {
    event.stopPropagation();
    setIsEditing(true);
    setOpenEditPostDialog(true);
  };

  const handleCloseEditPostDialog = () => {
    setOpenEditPostDialog(false);
    handleMenuClose(post?._id);
  };

  const handleAttendEvent = async () => {
    const result = await attendEvent({
      userId: currentUserId,
      postId: post?._id,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        if (!isPostsError && !isPostsLoading) {
          refetchPosts?.();
        }
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `The error occured ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleUnattendEvent = async () => {
    const result = await unattendEvent({
      userId: currentUserId,
      postId: post?._id,
    });
    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        refetchPosts?.();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `The error occured ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleDeleteAllRecurringPosts = async () => {
    const result = await deleteAllRecurringPosts({
      postId: post?._id,
      tierId: currentTierId,
    });

    if (result.data) {
      setNotification({ open: true, message: `${result?.data?.msg}` });
      handleDeleteAllRecurringPostsDialogClose();
    } else {
      setNotification({
        open: true,
        message: `Error deleting post. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleDeleteAllRecurringPostsDialogOpen = () => {
    setOpenDeleteAllRecurringPostsDialog(true);
  };

  const handleDeleteAllRecurringPostsDialogClose = (event) => {
    event && event.stopPropagation();
    setOpenDeleteAllRecurringPostsDialog(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleMemberClick = (memberId) => {
    setSelectedMemberId(memberId);
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setSelectedMemberId(null);
  };

  const additionalAttends = attends?.length > 3 ? attends?.length - 3 : 0;

  const renderAttendsAvatars = () => {
    return attends?.slice(0, 3).map((attend, index) => (
      <Tooltip
        key={index}
        title={`${attend?.firstName} ${attend?.lastName}`}
        placement="bottom"
      >
        <Avatar
          src={attend?.image?.url}
          alt={`${attend?.firstName} ${attend?.lastName}`}
          sx={{ width: 24, height: 24, marginRight: 0, cursor: "pointer" }}
        />
      </Tooltip>
    ));
  };

  const handleRSVPStatusChange = async (newStatus) => {
    setRsvpStatus(newStatus);
    if (newStatus === "Going") {
      handleAttendEvent();
    } else if (newStatus === "Not Going") {
      handleUnattendEvent();
    }
  };

  const isAdmin = accountType === "reosadmin" || accountType === "brokerage";
  const isAuthor = post?.author?._id === currentUserId;
  const isAuthorOrAdmin = isAuthor || isAdmin;
  const sanitizedDescription = post?.description
    ? DOMPurify.sanitize(post.description)
    : "";
  const isAllOffices =
    post?.tier &&
    post?.tier?.brokerage &&
    post?.tier?.brokerage?.leadBrokerageTier &&
    post?.tier?._id === post?.tier?.brokerage?.leadBrokerageTier?._id;

  return (
    <Card
      className={`post-card ${
        isPostList && categoryType !== "event" ? "margin-bottom" : ""
      } ${viewMode === "grid-view" ? "grid-view" : "list-view"}`}
    >
      {isNewPost && <Box className="post-card-new-post-label">NEW POST</Box>}
      {isPostHomePageList &&
        post?.tier &&
        post?.tier?.brokerage?.officeName && (
          <Box className="post-card-tier-label">
            {`${post?.tier?.brokerage?.officeName}${
              isAllOffices ? " - all offices" : ""
            }` || "Unknown Office"}
          </Box>
        )}
      {viewMode !== "grid-view" && post?.image && post?.image?.url && (
        <div className="aspect-ratio-16-9">
          <CardMedia
            component="img"
            className="aspect-ratio-content"
            image={
              post?.image?.url.includes("cloudinary")
                ? post?.image?.url
                : downloadedImage?.url
            }
            alt="Post"
          />
        </div>
      )}
      {(!isPostList || (!isPostList && isAdmin)) && (
        <CardHeader
          className="post-card-header"
          avatar={
            <Tooltip title="Member Profile link">
              <AvatarStyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
                color="success"
                invisible={!post?.author?.isOnline}
                width="5px"
                height="5px"
              >
                <Avatar
                  className="avatar"
                  src={post?.author?.image?.url || ""}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleMemberClick(post?.author?._id);
                  }}
                >
                  R
                </Avatar>
              </AvatarStyledBadge>
            </Tooltip>
          }
          action={
            <>
              <IconButton
                className="menu-button"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(event) => handleMenuOpen(event, post?._id)}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id={`menu-${post?._id}`}
                anchorEl={menuAnchorEl[post?._id]}
                open={Boolean(menuAnchorEl[post?._id])}
                onClose={() => handleMenuClose(post?._id)}
              >
                {isAuthorOrAdmin && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOpenEditPostDialog(event);
                    }}
                  >
                    <Edit />
                    <Typography ml={1}>Edit Post</Typography>
                  </MenuItem>
                )}
                {isAuthorOrAdmin && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      onMigratePostToNewCategoryDialogOpen(post);
                      handleMenuClose(post?._id);
                    }}
                  >
                    <Edit />
                    <Typography ml={1}>Migrate Post To New Category</Typography>
                  </MenuItem>
                )}
                {isAuthorOrAdmin && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      onDialogOpen(post?._id);
                    }}
                  >
                    <DeleteForever />
                    <Typography ml={1}>Delete Post</Typography>
                  </MenuItem>
                )}
                {isAuthorOrAdmin &&
                  post?.eventDetails &&
                  post?.eventDetails?.recurrence &&
                  post?.recurrenceGroupId && (
                    <MenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteAllRecurringPostsDialogOpen();
                      }}
                    >
                      <DeleteForever />
                      <Typography ml={1}>
                        {isDeleteAllRecurringPostsLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Delete All recurring Event Posts"
                        )}
                      </Typography>
                    </MenuItem>
                  )}
                {!(accountType === "reosadmin") && !isAuthorOrAdmin && (
                  <MenuItem>
                    <Typography ml={1}>No actions</Typography>
                  </MenuItem>
                )}
              </Menu>
            </>
          }
          title={
            <div className="card-header-title">
              <Tooltip title="Member Profile link">
                <Link
                  onClick={(event) => {
                    event.stopPropagation();
                    handleMemberClick(post?.author?._id);
                  }}
                  className="avatar-name"
                >
                  {post?.author?.firstName || ""} {post?.author?.lastName || ""}
                </Link>
              </Tooltip>
              <Typography className="category-text">
                {" posted in "}
                {post?.category?.name || ""}
              </Typography>
            </div>
          }
          subheader={
            <Typography className="date-text">
              {new Date(post?.createdAt).toLocaleString() || ""}
            </Typography>
          }
        />
      )}
      <CardHeader
        className="post-card-header"
        avatar={
          <div className="post-card-header-date">
            <Tooltip title="Event Date">
              <Typography className="post-card-header-date-day">
                {formattedDateToDay}
              </Typography>
            </Tooltip>
            <Tooltip title="Event Date">
              <Typography className="post-card-header-date-month">
                {formattedDateToMonth}
              </Typography>
            </Tooltip>
          </div>
        }
        title={
          <div className="post-card-title-wrap">
            <Typography className="post-card-title">
              {post?.postTitle || ""}
            </Typography>
            {post?.accessCount > 0 && (
              <Tooltip
                title={`Post Viewed ${post?.accessCount} time${
                  post?.accessCount === 1 ? "" : "s"
                }`}
              >
                <Badge badgeContent={post?.accessCount} color="primary">
                  <Visibility />
                </Badge>
              </Tooltip>
            )}
          </div>
        }
        sx={{ cursor: "pointer" }}
        onClick={(event) => {
          if (isPostList && !isDrawerOpen && !isEditing) {
            onSelect(event);
          }
        }}
        action={
          <div className="post-card-actions">
            <div className="likes-block">
              <Tooltip
                title={
                  isAttendedByCurrentUser
                    ? "Unattend the Event"
                    : "Attend the Event"
                }
                placement="left"
              >
                <IconButton className="btn btn-attend">
                  <RSVPButton
                    status={rsvpStatus}
                    onStatusChange={handleRSVPStatusChange}
                  />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        }
        subheader={
          <div
            className={`${
              (isSmallDevice && !sidebarOpen) || !isPostList
                ? "post-card-header-date-local-small"
                : "post-card-header-date-local"
            }`}
          >
            <div className="post-card-header-date-local-event-type-item">
              <CalendarToday />
              <Typography ml={1}>
                {`${eventDateAndTime}${
                  eventEndDateAndTime ? " - " + eventEndDateAndTime : ""
                }`}
              </Typography>
            </div>
            {post?.eventDetails?.isOnline ? (
              <div className="post-card-header-date-local-event-type-item">
                <VideoChat />
                <Typography ml={1}>
                  Virtual {post?.eventType && post?.eventType?.name}
                </Typography>
              </div>
            ) : (
              <div className="post-card-header-date-local-event-type-item">
                <PinDrop />
                <Typography ml={1}>
                  In-person {post?.eventType && post?.eventType?.name}
                </Typography>
              </div>
            )}
          </div>
        }
      />
      <CardContent className="custom-card-content">
        {viewMode !== "grid-view" && post?.videos && post.videos.length > 0 && (
          <PostVideos videos={post.videos} isPostPage={isPostPage} />
        )}
        {viewMode !== "grid-view" &&
          post?.attachments &&
          post.attachments.length > 0 && (
            <Attachments attachments={post.attachments} />
          )}
        {post?.description && !isPostList && (
          <>
            <Typography
              variant="subtitle1"
              sx={{
                mb: 1,
                fontWeight: "bolder",
                textDecoration: "underline",
              }}
            >
              Event Description
            </Typography>
            <Box sx={{ mt: 1, mb: 1, p: 1 }} component={Paper}>
              <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
            </Box>
          </>
        )}
        {!isPostList && (
          <div className="details-block">
            <Typography
              className="details-block-title"
              sx={{
                mt: 1,
                fontWeight: "bolder",
                textDecoration: "underline",
              }}
            >
              Event Details
            </Typography>
            <div className="details-block-address">
              {post?.eventDetails?.address && !post?.eventDetails?.isOnline && (
                <div>
                  <Typography
                    m={0.5}
                    className="details-block-address-street-suit"
                  >
                    {post?.eventDetails?.address?.suit
                      ? post?.eventDetails?.address?.suit + " - "
                      : ""}

                    {post?.eventDetails?.address?.street || ""}
                  </Typography>
                  <Typography m={0.5} className="details-block-address-city">
                    {post?.eventDetails?.address?.city &&
                      `${post.eventDetails.address.city}`}
                    {post?.eventDetails?.address?.city &&
                      post?.eventDetails?.address?.state &&
                      ", "}
                    {post?.eventDetails?.address?.state &&
                      `${post.eventDetails.address.state}`}
                    {(post?.eventDetails?.address?.city ||
                      post?.eventDetails?.address?.state) &&
                      post?.eventDetails?.address?.country &&
                      ", "}
                    {post?.eventDetails?.address?.country &&
                      `${post.eventDetails.address.country}`}
                  </Typography>
                </div>
              )}
              {post?.eventDetails?.isOnline && (
                <div>
                  Meeting Link:
                  <LinkComponent
                    href={post?.eventDetails?.link || ""}
                    target="_blank"
                    rel="noopener noreferrer"
                    m={0.5}
                    className="details-block-link"
                  >
                    {decodeURIComponent(post?.eventDetails?.link) || ""}
                  </LinkComponent>
                </div>
              )}
            </div>
          </div>
        )}
        {viewMode !== "grid-view" && post?.lastEdited && (
          <Typography variant="caption" color="textSecondary" textAlign="right">
            <EditIcon sx={{ verticalAlign: "middle", mr: 0.5 }} />
            Last edited on {new Date(post?.lastEdited)?.toLocaleString()}
          </Typography>
        )}
        {viewMode !== "grid-view" && post?.tags && post.tags.length > 0 && (
          <div className="tag-box">
            <Box
              sx={{
                mt: 2,
                mb: 2,
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
              }}
            >
              {Array.isArray(post.tags)
                ? post.tags.map((tag, index) => (
                    <Chip
                      className="tag-box-item"
                      key={index}
                      label={tag}
                      variant="outlined"
                    />
                  ))
                : post.tags
                    .split(",")
                    .map((tag, index) => (
                      <Chip
                        className="tag-box-item"
                        key={index}
                        label={tag.trim()}
                        variant="outlined"
                      />
                    ))}
            </Box>
          </div>
        )}

        <div>
          {isPostList && (
            <Button
              sx={{ marginTop: "10px" }}
              variant="outlined"
              color="success"
              onClick={(event) => {
                if (!isDrawerOpen && !isEditing) {
                  onSelect(event);
                }
              }}
            >
              View Event...
            </Button>
          )}
        </div>
        <div className="attends-block">
          {!isPostList && (
            <Typography className="attends-block-title">
              {attends?.length > 0
                ? `${attends?.length} Attendees`
                : "No Attendees"}
            </Typography>
          )}
          <Tooltip
            title={additionalAttends > 0 ? `+ ${additionalAttends} more` : ""}
            placement="right"
          >
            <Badge
              badgeContent={
                additionalAttends > 0 ? `+${additionalAttends}` : ""
              }
              onMouseEnter={handleAttendeesPopoverOpen}
              onClick={handleAttendeesPopoverOpen}
              style={{ cursor: "pointer" }}
              className="attends-block-avatars"
            >
              <div className="attends-avatars">{renderAttendsAvatars()}</div>
            </Badge>
          </Tooltip>
        </div>
        {post?.attends && post?.attends?.length > 0 && (
          <Popover
            open={openAttendees}
            anchorEl={anchorAttendeesEl}
            onClose={handleAttendeesPopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <List
              style={{
                maxHeight: 300,
                overflow: "auto",
                width: "100%",
                maxWidth: 360,
              }}
            >
              {post?.attends &&
                post?.attends?.map((attendee, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar src={attendee?.image?.url || ""} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${attendee?.firstName} ${attendee?.lastName}`}
                    />
                  </ListItem>
                ))}
            </List>
          </Popover>
        )}
      </CardContent>
      <Divider />
      <EditPost
        refetchPosts={refetchPosts}
        post={post}
        open={openEditPostDialog}
        onClose={() => {
          handleCloseEditPostDialog();
          handleMenuClose(post?._id);
        }}
      />
      <ConfirmationDialog
        open={openDeleteAllRecurringPostsDialog}
        onClose={handleDeleteAllRecurringPostsDialogClose}
        onConfirm={handleDeleteAllRecurringPosts}
        title="Confirm Deleting"
        description="Are you sure you want to delete these recurring event posts?"
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <MemberProfileDrawer
        userId={selectedMemberId}
        open={isDrawerOpen}
        onClose={handleDrawerClose}
      />
    </Card>
  );
};

export default memo(EventPostCard);
