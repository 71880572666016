import React, { useState, useEffect } from "react";
import { Tabs, Tab, Paper, Typography, Box } from "@mui/material";
import { TabPanel } from "../../helpers/utils";
import ListingTourDataGridList from "./ListingTourDataGridList";
import NotificationSnackbar from "../../helpers/notification-snackbar";

function ListingTourTabs({ listingTours }) {
  const [selectListingTourId, setSelectListingTourId] = useState("");
  const [selectListingTour, setSelectListingTour] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (listingTours && listingTours.length > 0) {
      const initialScheduleId = listingTours[0]._id || "";
      setSelectListingTourId(initialScheduleId);
      setSelectListingTour(listingTours[0]);
    }
  }, [listingTours]);

  const handleTabChange = (event, newValue) => {
    setSelectListingTourId(newValue);
    const selectedListingTour = listingTours?.find(
      (listingTour) => listingTour?._id === newValue
    );
    setSelectListingTour(selectedListingTour);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (!listingTours || listingTours?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
          width: "100%",
          height: "100%",
        }}
      >
        <Typography variant="h5" gutterBottom>
          No Listing Tours available
        </Typography>
      </Box>
    );
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Paper elevation={4} sx={{ width: "100%", p: "1rem" }}>
        <Tabs
          value={selectListingTourId || listingTours[0]._id}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons
          selectionFollowsFocus
          aria-label="scrollable auto tabs example"
          className="tier-tabs-paper"
          component={"div"}
        >
          {listingTours?.map((listingTour, i) => {
            const listingTourTitleLabel = listingTour?.listingTourTitle;
            const listingTourDateLabel = new Date(
              listingTour?.listingTourDate
            )?.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            });

            return (
              <Tab
                key={listingTour?._id}
                label={`${listingTourTitleLabel} - ${listingTourDateLabel}`}
                value={listingTour?._id}
                id={`tab-${i}`}
              />
            );
          })}
        </Tabs>
        {listingTours?.map((listingTour) => {
          return (
            <TabPanel
              key={listingTour?._id}
              value={selectListingTourId}
              index={listingTour?._id}
              className="brokerage-tabs-paper"
            >
              {selectListingTourId === listingTour?._id && (
                <ListingTourDataGridList
                  listingTour={selectListingTour}
                  listingTours={listingTours || []}
                />
              )}
            </TabPanel>
          );
        })}
      </Paper>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </div>
  );
}

export default ListingTourTabs;
